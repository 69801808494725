const NavigateLink = {
	TO_BRANDS: 'brands',
	TO_MODELS: 'models',
	TO_WHATAPP: 'whatapp'
};

const Brand = {
	IPHONE: 'iphone',
	SAMSUNG: 'samsung',
	ANDROID: 'android'
};

const THEME_PATH = window.THEME_PATH || '';
const API_PATH = THEME_PATH ? `${THEME_PATH}/data` : './api';

(function() {
	window.addEventListener('popstate', function(e) {
		console.log(e);
	});

	function fetchFromUrl(url) {
		return new Promise((resolve, reject) => {
			fetch(url)
			.then(response => {
				if (response.ok) {
					return response.json();
				}

				return {error: 'Ошибка при запросе'}
			})
			.then(resolve)
			.catch(reject);
		});
	}

	function getServiceList() {
		return fetchFromUrl(`${API_PATH}/service/services.json?ts=${Date.now()}`);
	}

	function getBrandContent(brand = Brand.IPHONE) {
		return fetchFromUrl(`${API_PATH}/${brand}/content.json?ts=${Date.now()}`);
	}

	function getPhoneServicePrice(idPhone, brand = Brand.IPHONE) {
		return new Promise(resolve => {
			fetchFromUrl(`${API_PATH}/${brand}/prices.json?ts=${Date.now()}`).then(prices => {
				if (Array.isArray(prices)) {
					const phonePrices = prices.find(item => item.idPhone === Number(idPhone))
					if (phonePrices && phonePrices.services) {
						resolve(phonePrices.services);
					} else {
						resolve([]);
					}
				} else {
					resolve([]);
				}
			});
		})
	}

	const serviceModule = () => {
		/* init vars */
		let servicesList = [];
		let whatappNumber = '';
		let defaultTitleText = ''

		const titleElement = document.querySelector('.js-service-title');
		if (titleElement) {
			defaultTitleText = titleElement.textContent;
		}

		const brandElements = document.querySelectorAll('.js-service-brand');
		const brandContentElement = document.querySelector('.js-brand-content');
		const navigateElement = document.querySelector('.js-service-navigate');

		const whatappServiceElement = document.querySelector('.js-whatapp-service');
		const whatappLinkElement = document.querySelector('.js-whatapp-service__link');

		if (whatappLinkElement) {
			whatappNumber = whatappLinkElement.getAttribute('href');
		}

		if (brandElements) {
			[...brandElements].forEach(brand => brand.addEventListener('click', e => {
				const brand = e.currentTarget.getAttribute('data-brand');
				renderModels(brand);
				scrollToTitle();
			}));
		}

		/* start */
		renderBrands();

		/* functions */
		function hide(element) {
			if (element) {
				element.classList.add('hidden');
			}
		}

		function show(element) {
			if (element) {
				element.classList.remove('hidden');
			}
		}

		function changeTitle(text) {
			titleElement.textContent = text;
		}

		function renderBrands() {
			changeTitle(defaultTitleText);
			brandElements.forEach(show);
			hide(brandContentElement);
			hide(navigateElement);
			hide(whatappServiceElement);
		}

		function scrollToTitle() {
			titleElement.scrollIntoView();
		}

		function renderModels(brand = Brand.IPHONE) {
			function renderWhatApp() {
				changeTitle(`Смартфон на ${brand}`);

				hide(brandContentElement);
				show(whatappServiceElement);

				renderNavigate([
					{href: NavigateLink.TO_BRANDS, text: 'Изменить бренд'},
					{href: NavigateLink.TO_MODELS, text: 'Другая модель'}
				], brand);

				brandElements.forEach(hide);
				show(navigateElement);
			};

			if (brand === Brand.ANDROID) {
				renderWhatApp();
				return;
			}

			changeTitle(`Выберите модель ${brand.toUpperCase()}`);
			getBrandContent(brand).then(data => {
				if (data.error || !Array.isArray(data)) {
					// если ошибка
					renderWhatApp();
				} else {
					brandContentElement.innerHTML = (`
						<ul class="model-list">
							${data.map(item => {
								return (`
									<li class="model-list-item">
										<a href="#${item.model}" class="model-list-item__link js-model-list-item__link" data-model="${item.model}" data-id="${item.id}">
											<div class="model-list-item__img">
												<img src="${THEME_PATH}/assets/images/${brand}/${item.picture}" alt="${item.model}"/>
											</div>
											<h4 class="model-list-item__title">${item.model}</h4>
										</a>
									</li>
								`).trim()
							}).join('')}
						</ul>
					`).trim();

					const models = brandContentElement.querySelectorAll('.js-model-list-item__link');
					if (models) {
						[...models].forEach(model => model.addEventListener('click', e => {
							e.preventDefault();
							const target = e.currentTarget;
							const model = target.getAttribute('data-model');
							const idPhone = target.getAttribute('data-id');
							const imgSrc = target.querySelector('img').getAttribute('src');

							renderServices({idPhone, model, imgSrc, brand});
							scrollToTitle();
						}));
					}

					renderNavigate([
						{href: NavigateLink.TO_BRANDS, text: 'Изменить бренд'},
						{href: NavigateLink.TO_WHATAPP, text: 'Написать в WhatApp'}
					], brand);
				}
			});

			brandElements.forEach(hide);
			hide(whatappServiceElement);
			show(brandContentElement);
			show(navigateElement);
		}

		function renderServices({idPhone, model, imgSrc, brand = Brand.IPHONE}) {
			changeTitle(`Услуги по ремонту ${model}`);

			if (servicesList.length === 0) {
				// если список работ пуст, запрашиваем с api
				getServiceList().then(services => {
					if (Array.isArray(services)) {
						render(services);
					}
				});
			} else {
				render(serviceList);
			}

			function render(data) {
				getPhoneServicePrice(idPhone, brand).then(phoneServices => {
					if (phoneServices.length === 0) {
						// если нет данных по ценам, показываем кнопку с whatapp
						hide(brandContentElement);
						show(whatappServiceElement);

						renderNavigate([
							{href: NavigateLink.TO_BRANDS, text: 'Изменить бренд'},
							{href: NavigateLink.TO_MODELS, text: 'Другая модель'}
						], brand);
					} else {
						function getServiceName(idService) {
							const current = data.find(service => service.id === idService);
							return current ? current.name : '-'
						}

						const services = phoneServices.map(item => ({
							name: getServiceName(item.idService),
							price: item.price,
							isRange: item.isRange
						}));

						const getElementTemplate = (service) => (`
							<li class="phone-service-list__item">
								<h5 class="phone-service-list__title">${service.name}</h5>
								<span class="phone-service-list__price">
									${service.isRange ? 'от ': ''}
									${service.price === '0' ? 'Бесплатно' : `${service.price}&#8381`}
								</span>
							</li>
						`).trim();

						const servicesLeft = [];
						const servicesRight = [];

						const separatorIndex = Math.floor(services.length / 2);

						services.forEach((item, index) => {
							const service = getElementTemplate(item);
							if (index < separatorIndex) {
								servicesLeft.push(service);
							} else {
								servicesRight.push(service);
							}
						});

						brandContentElement.innerHTML = (`
							<div class="phone-service">
								<ul class="phone-service-list left">
									${servicesLeft.join('')}
								</ul>
								<div class="phone-service__img">
									<img src="${imgSrc}">
								</div>
								<ul class="phone-service-list right">
									${servicesRight.join('')}
								</ul>
							</div>
						`).trim();

						renderNavigate([
							{href: NavigateLink.TO_BRANDS, text: 'Изменить бренд'},
							{href: NavigateLink.TO_MODELS, text: 'Другая модель'},
							{href: NavigateLink.TO_WHATAPP, text: 'Написать в WhatApp'}
						], brand);
					}
				});
			}
		}

		function renderNavigate(links, brand = Brand.IPHONE) {
			navigateElement.innerHTML = links.map(item => {
				isWhatapp = item.href === NavigateLink.TO_WHATAPP;
				const attr = isWhatapp ? ' target="_blank" rel="noopener" class="service-navigate__whatapp"' : '';

				return (`
					<li>
						<a href="${isWhatapp ? whatappNumber : item.href}" data-type="${item.href}"${attr}' : ''}>${item.text}</a>
					</li>
				`).trim()
			}).join('');

			[...navigateElement.querySelectorAll('a')].forEach(link => {
				link.addEventListener('click', e => {
					const type = e.currentTarget.getAttribute('data-type');

					switch (type) {
						case NavigateLink.TO_BRANDS:
							e.preventDefault();
							renderBrands();
							break;
						case NavigateLink.TO_MODELS:
							e.preventDefault();
							renderModels(brand);
							break;
						default:
							break;
					}

					scrollToTitle();
				});
			});
		}
	};

	document.addEventListener("DOMContentLoaded", function() {
		serviceModule();
	});
})();